import React from "react"
import {
  Header,
  BlogCardBig,
  BlogCardSmall,
  BlogCardSmallExpanded,
  ButtonPrimary,
  Footer,
  NewsletterBig,
  Subtitle,
  Intro,
} from "../components/index"
import "../styles/app.css"
import { graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Helmet from "react-helmet"
import Favicon from "../img/favicon.ico"

export default ({ data }) => (
  <>
    <Helmet>
      <html lang="en" />
      <title>Buyneosurf Blog – Neosurf Online Vouchers News</title>
      <meta
        name="description"
        content="Latest news and articles related to the Neosurf, Myneosurf, online payment methods and voucher systems. Stay informed & visit Buyneosurf Blog."
      />
      <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
    </Helmet>
    <Header />
    <Intro />
    <div className="grid">
      <div className="left">
        {data.recentPosts.nodes.map(node => {
          const slug = `/${node.slug}`
          return (
            <BlogCardBig
              slug={slug}
              title={node.title}
              description={documentToReactComponents(node.description.json)}
              date={node.published}
              alt={node.title}
              img={node.banner && node.banner.fluid ? node.banner : null}
            />
          )
        })}
      </div>
      <div className="right">
        <Subtitle>Most popular News / Blog</Subtitle>
        {data.popular.edges[0].node.post.map(node => {
          const slug = `/${node.slug}`
          return (
            <BlogCardSmall
              slug={slug}
              title={node.title}
              description={documentToReactComponents(node.description.json)}
              date={node.published}
              alt={node.title}
              img={node.banner && node.banner.fluid ? node.banner : null}
            />
          )
        })}
      </div>
    </div>
    <div className="bottom-posts-and-subtitle">
      <Subtitle>TRENDING NEWS</Subtitle>
      <div className="bottom-posts">
        {data.trending.edges[0].node.post.map(node => {
          const slug = `/${node.slug}`
          return (
            <BlogCardSmallExpanded
              slug={slug}
              title={node.title}
              description={`${node.description.json.content[0].content[0].value.substring(
                0,
                200
              )}...`}
              date={node.published}
              alt={node.title}
              img={node.banner && node.banner.fluid ? node.banner : null}
            />
          )
        })}
      </div>
    </div>
    <ButtonPrimary />

    <div className="pagination">
      <Link to="/blog">See all posts</Link>
    </div>

    <NewsletterBig />
    <Footer />
  </>
)

export const query = graphql`
  query MyQuery2 {
    allImageSharp {
      nodes {
        id
        fluid(maxWidth: 4000) {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
    recentPosts: allContentfulPost(
      limit: 5
      sort: { fields: [published], order: DESC }
    ) {
      nodes {
        id
        slug
        published(formatString: "DD/MM/YYYY - h:m A")
        title
        description {
          json
        }
        content {
          json
        }
        category {
          name
        }
        banner {
          fluid(maxWidth: 2000) {
            base64
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
    }
    trending: allContentfulTrendingPosts {
      edges {
        node {
          post {
            id
            slug
            published(formatString: "DD/MM/YYYY - h:m A")
            title
            description {
              json
            }
            content {
              json
            }
            category {
              name
            }
            metaTitle
            metaDescription
            banner {
              fluid {
                base64
                sizes
                src
                srcSet
              }
              title
            }
          }
        }
      }
    }
    popular: allContentfulMostPopularNews {
      edges {
        node {
          post {
            id
            slug
            published(formatString: "DD/MM/YYYY - h:m A")
            title
            description {
              json
            }
            content {
              json
            }
            category {
              name
            }
            metaTitle
            metaDescription
            banner {
              fluid {
                base64
                sizes
                src
                srcSet
              }
              title
            }
          }
        }
      }
    }
    popularPosts: allContentfulPost(limit: 6) {
      nodes {
        id
        slug
        published(formatString: "DD/MM/YYYY - h:m A")
        title
        description {
          json
        }
        content {
          json
        }
        category {
          name
        }
        banner {
          fluid(maxWidth: 2000) {
            base64
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
    }
    trendingPosts: allContentfulPost(limit: 3) {
      nodes {
        id
        slug
        published(formatString: "DD/MM/YYYY - h:m A")
        title
        description {
          json
        }
        content {
          json
        }
        category {
          name
        }
        banner {
          fluid(maxWidth: 2000) {
            base64
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
    }
  }
`
